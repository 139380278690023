import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import ImgMissionCompany from "../../images/index_mission_company.jpg";
import ImgMissionProduct from "../../images/index_mission_product.jpg";

export const IndexMissionSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <STxtWrapper>
          <SectionHeading jp="ミッション" en="Mission" />
          <p>
            サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
          </p>
        </STxtWrapper>
        <SBtnWrapper>
          <Link to="/company/">
            <span className="heading">company</span>
            <span className="title">会社案内</span>
            <span className="icon">
              <i className="material-icons">trending_flat</i>
            </span>
            <img src={ImgMissionCompany} alt="company" />
          </Link>
          <Link to="/product/">
            <span className="heading">product</span>
            <span className="title">製品案内</span>
            <span className="icon">
              <i className="material-icons">trending_flat</i>
            </span>
            <img src={ImgMissionProduct} alt="product" />
          </Link>
        </SBtnWrapper>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  background-color: ${Color.primary};
  padding: 4rem 0;
  .container {
    display: flex;
    justify-content: space-between;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
`;

const STxtWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 0.9rem;
    margin-top: 2rem;
    color: ${Color.light};
    font-weight: 500;
    padding-right: 2rem;
  }
  .section-heading {
    span {
      color: ${Color.light};
    }
  }

  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const SBtnWrapper = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: flex-end;
  a {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    aspect-ratio: 16/9;
    text-decoration: none;
    background-color: #fff;
    padding: 1rem;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    background-color: ${Color.primary};
    img {
      transition: all 0.2s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      object-fit: cover;
      object-position: center;
    }
    span {
      line-height: 1;
      z-index: 2;
    }
    .heading {
      display: inline-flex;
      align-items: center;
      text-align: left;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${Color.light};
      &::before {
        content: "";
        background-color: ${Color.light};
        width: 4px;
        height: 4px;
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 100vh;
      }
    }
    .title {
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      color: ${Color.light};
    }
    .icon {
      text-align: right;
      transform: translateX(-1rem);
      transition: all 0.2s;
      i {
        font-size: 0.9rem;
        border: 1px solid ${Color.light};
        padding: 4px;
        border-radius: 100vh;
        color: ${Color.light};
      }
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        box-shadow: 0px 0px 16px -6px #666;
        img {
          transform: scale(1.2);
        }
        .icon {
          transform: translateX(0);
        }
      }
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
    a {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .heading {
        font-size: 1rem;
      }
      .title {
        font-size: 1.2rem;
      }
      &:active {
        box-shadow: 0px 0px 16px -6px #666;
        img {
          transform: scale(1.2);
        }
        .icon {
          transform: translateX(0);
        }
      }
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

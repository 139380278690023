import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import { NewsCard } from "../card/newsCard";
import { DefaultBtn } from "../btn/defaultBtn";

export const IndexNewsSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SWrapper>
          <SectionHeading jp="お知らせ" en="News" position="left" />
          <SCardWrap>
            <NewsCard
              date="2021.01.01"
              title="記事タイトル"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
            <NewsCard
              date="2021.01.01"
              title="記事タイトル"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
            <NewsCard
              date="2021.01.01"
              title="記事タイトル"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
          </SCardWrap>
          <DefaultBtn to="/" txt="お知らせ一覧" color="primary" />
        </SWrapper>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  background-color: ${Color.light};
  .container {
    display: flex;
    justify-content: flex-end;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    .container {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const SWrapper = styled.div`
  width: calc(100% - 400px);
  padding: 4rem;
  padding-right: 0;
  .default-btn {
    margin-top: 2rem;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 12rem;
  }
`;

const SCardWrap = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
`;

import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { SectionHeading } from "../heading/sectionHeading";
import { ContainerWrapper } from "../wrapper/containerWrapper";
import { ReasonCard } from "../card/reasonCard";

import ImgCamera from "../../images/index_reason_camera.png";
import ImgGolf from "../../images/index_reason_golf.png";
import ImgHospital from "../../images/index_reason_hospital.png";
import ImgEye from "../../images/index_reason_eye.png";
import ImgSoftware from "../../images/index_reason_software.png";
import ImgSupport from "../../images/index_reason_support.png";
import Bg from "../../images/index_reason_bg.png";

export const IndexReasonSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SHeading>
          <SectionHeading jp="選ばれる理由" en="Reason Why" position="center" />
        </SHeading>
        <SCardWrapper>
          <ReasonCard
            img={ImgCamera}
            label="Hyspeed Camera"
            lead="小型軽量かつ高性能なカメラにより、肉眼では確認できない撮影対象の分析や観察・記録をすることができます。"
          />
          <ReasonCard
            img={ImgGolf}
            label="Golf Simulator"
            lead="最新のカメラセンサーを搭載したゴルフシミュレーターにより、リアルなプレイを再現できます。"
          />
          <ReasonCard
            img={ImgHospital}
            label="Medical Device"
            lead="高性能かつ高感度カメラにより、より良い医療提供と環境を実現することができます。"
          />
          <ReasonCard
            img={ImgEye}
            label="Eye Tracker"
            lead="シミュレーターと組み合わせて使用することで、よりリアルな体験が可能になる機器の販売をしております。"
          />
          <ReasonCard
            img={ImgSoftware}
            label="Software"
            lead="撮影・記録したデータを解析するソフトウェアの提供を、弊社製品とセットで販売しております。"
          />
          <ReasonCard
            img={ImgSupport}
            label="Chat Support"
            lead="ソフトウェア・ファームウェアのアップデートや、製品仕様についてのチャットサポートを行なっております。"
          />
        </SCardWrapper>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  background-color: ${Color.light};
  background-image: url(${Bg});
`;

const SHeading = styled.div`
  text-align: center;
`;

const SCardWrapper = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
    grid-template-columns: 1fr;
  }
`;

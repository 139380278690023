import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const DefaultBtn = (props) => {
  return (
    <SBtn className="default-btn" data-btn-color={props.color}>
      <Link to={props.to}>
        {props.txt}
        <span className="material-icons">trending_flat</span>
      </Link>
    </SBtn>
  );
};

const SBtn = styled.div`
  display: inline-flex;
  a {
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 100vh;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s;
    span {
      margin-left: 1rem;
      transition: all 0.2s;
    }
    &:hover {
      span {
        transform: translateX(1rem);
      }
    }
  }
  &[data-btn-color="primary"] {
    a {
      color: ${Color.light};
      background-color: ${Color.primary};
      padding: 1rem 2rem;
      span {
        color: ${Color.light};
      }
      &:hover {
        background-color: ${Color.dark};
      }
    }
  }
  &[data-btn-color="light"] {
    a {
      color: ${Color.primary};
      background-color: ${Color.light};
      padding: 1rem 2rem;
      span {
        color: ${Color.primary};
      }
      &:hover {
        background-color: ${Color.dark};
      }
    }
  }
`;

import React from "react";
import styled from "@emotion/styled";
import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import ImgRecruitHeading from "../../images/index_recruit_heading.jpg";
import { DefaultBtn } from "../btn/defaultBtn";

export const IndexRecruitSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SWrapper>
          <SectionHeading en="Recruit" jp="採用情報" position="left" />
          <p>
            サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
            <br />
            <br />
            サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
          </p>
          <DefaultBtn to="/recruit/" txt="採用情報" color="primary" />
        </SWrapper>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  height: 480px;
  background-image: url(${ImgRecruitHeading});
  background-position: center;
  background-size: cover;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const SWrapper = styled.div`
  margin-bottom: -160px;
  width: 400px;
  background-color: #fff;
  padding: 2rem;
  opacity: 0.9;
  p {
    margin-top: 3rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .default-btn {
    margin-top: 3rem;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

import React from "react";
import { Layout } from "../components/layout/layout";
import { IndexHeroSection } from "../components/section/indexHeroSection";
import { IndexAboutSection } from "../components/section/indexAboutSection";
import { IndexReasonSection } from "../components/section/indexReasonSection";
import { IndexMissionSection } from "../components/section/indexMissionSection";
import { IndexNewsSection } from "../components/section/indexNewsSection";
import { IndexRecruitSection } from "../components/section/indexRecruitSection";

const HomePage = () => {
  return (
    <Layout title="LIME" jpUrl="/" enUrl="/en/" mainWrap="index">
      <IndexHeroSection />
      <IndexAboutSection />
      <IndexMissionSection />
      <IndexReasonSection />
      <IndexRecruitSection />
      <IndexNewsSection />
    </Layout>
  );
};

export default HomePage;

import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import ImgHeroBg001 from "../../images/index_hero_bg01.jpg";
import ImgHeroBg002 from "../../images/index_hero_bg02.jpg";
import ImgHeroBg003 from "../../images/index_hero_bg03.jpg";

export const IndexHeroSection = () => {
  return (
    <SSection>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        swipeable={false}
        infiniteLoop={true}
        showStatus={false}
        width="100%"
        interval="3000"
        transitionTime="500"
      >
        <SWrapper01>
          <div>
            <h1>SAMPLE</h1>
            <p>sample text</p>
            <p>sample</p>
          </div>
        </SWrapper01>
        <SWrapper02>
          <div>
            <h1>SAMPLE</h1>
            <p>sample text</p>
            <p>sample</p>
          </div>
        </SWrapper02>
        <SWrapper03>
          <div>
            <h1>SAMPLE</h1>
            <p>sample text</p>
            <p>sample</p>
          </div>
        </SWrapper03>
      </Carousel>
    </SSection>
  );
};

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .carousel-root {
    width: 100%;
    .carousel-slider {
      width: 100%;
    }
    .carousel {
      width: 100%;
      .slider-wrapper {
        height: 100vh;
        width: 100%;
      }
      .carousel-status {
        display: none;
      }
      button {
        display: none;
      }
      .thumbs-wrapper {
        display: none;
      }
      .control-dots {
        display: none;
      }
    }
  }
`;

const SWrapper01 = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${ImgHeroBg001});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    h1 {
      font-size: 4rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
      &:first-of-type {
      }
      &:last-of-type {
      }
    }
  }
`;
const SWrapper02 = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${ImgHeroBg002});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    h1 {
      font-size: 4rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
      &:first-of-type {
      }
      &:last-of-type {
      }
    }
  }
`;
const SWrapper03 = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${ImgHeroBg003});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    h1 {
      font-size: 3rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      font-weight: 700;
      color: #fff;
      &:first-of-type {
      }
      &:last-of-type {
      }
    }
  }
`;

import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const NewsCard = (props) => {
  return (
    <SCard>
      <SDate>{props.date}</SDate>
      <STitle>{props.title}</STitle>
      <SLead>{props.lead}</SLead>
    </SCard>
  );
};

const SCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  @media screen and (min-width: 1024px) {
    &:hover {
      box-shadow: 0px 0px 16px -6px #666;
    }
    &:active {
      box-shadow: none;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    &:active {
      box-shadow: 0px 0px 16px -6px #666;
    }
  }
`;

const SDate = styled.p`
  font-size: 0.8rem;
  font-weight: 700;
  color: ${Color.dark};
  line-height: 1;
`;

const STitle = styled.p`
  margin-top: 1rem;
  color: ${Color.primary};
  font-size: 1rem;
  font-weight: 700;
`;

const SLead = styled.p`
  margin-top: 0.5rem;
  font-size: 0.8rem;
`;

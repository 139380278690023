import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const ReasonCard = (props) => {
  return (
    <SCard>
      <SIcon>
        <img src={props.img} alt="icon" />
        <span>{props.label}</span>
      </SIcon>
      <SLead>{props.lead}</SLead>
    </SCard>
  );
};

const SCard = styled.div`
  background-color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 1rem;
  }
`;

const SIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
  span {
    color: ${Color.primary};
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    img {
      width: 64px;
      height: 64px;
    }
  }
`;

const SLead = styled.p`
  margin-top: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

import ImgAboutGolf from "../../images/index_about_golf.png";
import ImgAboutCamera from "../../images/index_about_camera.png";
import ImgAboutHospital from "../../images/index_about_hospital.png";

export const IndexAboutSection = () => {
  return (
    <SSection>
      <Link to="/product/category/">
        <p>サンプルテキストサンプルテキスト</p>
        <span>ゴルフ関連製品</span>
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
        <img src={ImgAboutGolf} alt="about" />
      </Link>
      <Link to="/product/category/">
        <p>サンプルテキストサンプルテキスト</p>
        <span>カメラ関連製品</span>
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
        <img src={ImgAboutCamera} alt="about" />
      </Link>
      <Link to="/product/category/">
        <p>サンプルテキストサンプルテキスト</p>
        <span>医療関連製品</span>
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
        <img src={ImgAboutHospital} alt="about" />
      </Link>
    </SSection>
  );
};

const SSection = styled.section`
  display: flex;
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin: auto;
    flex-direction: column;
  }
  a {
    position: relative;
    width: calc(100% / 3);
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    /* 背景色調整 */
    background-color: #808080;
    span {
      z-index: 2;
      line-height: 1;
      font-size: 1.2rem;
      font-weight: 700;
      color: ${Color.light};
      opacity: 0.9;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      &:after {
        content: "";
        width: 3rem;
        height: 0.5rem;
        background-color: ${Color.primary};
        display: inline-block;
        margin-top: 1rem;
        border-radius: 100vh;
      }
    }
    p {
      z-index: 2;
      font-size: 0.9rem;
      font-weight: 300;
      color: ${Color.light};
      opacity: 0.8;
    }
    img {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: transform 0.2s;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
    /* TB */
    @media screen and (max-width: 1023px) {
      width: calc(100% / 1);
      aspect-ratio: 2.5/1;
      justify-content: center;
      p {
        display: none;
      }
      &:active {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
`;
